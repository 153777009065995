import React from 'react';
import {Route} from 'react-router-dom';

import ErrorBoundaryRoutes from 'app/shared/error/error-boundary-routes';

import FacilityWarehouse from './facility-warehouse';
import Receipt from './receipt';
import ArtikeltexteDID from './artikeltexte-did';
import DataIdentifierSSC from './data-identifier-ssc';
import Deployment from './deployment';
import DeploymentAdministration from './deployment-administration';
import TransportOrder from './transport-order';
import Vehicle from './vehicle';
/* jhipster-needle-add-route-import - JHipster will add routes here */

export default () => {
  return (
    <div>
      <ErrorBoundaryRoutes>
        {/* prettier-ignore */}
        <Route path="facility-warehouse/*" element={<FacilityWarehouse />} />
        <Route path="receipt/*" element={<Receipt />} />
        <Route path="artikeltexte-did/*" element={<ArtikeltexteDID />} />
        <Route path="data-identifier-ssc/*" element={<DataIdentifierSSC />} />
        <Route path="deployment/*" element={<Deployment />} />
        <Route path="deployment-administration/*" element={<DeploymentAdministration />} />
        <Route path="transport-order/*" element={<TransportOrder />} />
        <Route path="vehicle/*" element={<Vehicle />} />
        {/* jhipster-needle-add-route-path - JHipster will add routes here */}
      </ErrorBoundaryRoutes>
    </div>
  );
};
