import './home.scss';

import React from 'react';
// import { Link } from 'react-router-dom';
import { Translate } from 'react-jhipster';
import { Alert, Col, Row } from 'reactstrap';
import { QRCodeCanvas } from 'qrcode.react';

import { useAppSelector } from 'app/config/store';

export const Home = () => {
  const account = useAppSelector(state => state.authentication.account);

  const buildQRCode = () => {
    let port = window.location.port;
    if (port.length === 0) {
      if (window.location.protocol.startsWith('https')) {
        port = '443';
      } else {
        port = '80';
      }
    }
    return ['ORGA-SOFT Coop Webservice', `${window.location.protocol}//${window.location.host.split(':')[0]}`, port, '', '', '', '', '', ''].join(
      ';'
    );
  };

  return (
    <Row>
      {/*
      <Col md="3" className="pad">
        <span className="hipster rounded" />
      </Col>
*/}
      <Col md="9">
        <h2>
          <Translate contentKey="home.title">Welcome to the ORGA-SOFT Coop Webservice!</Translate>
        </h2>
        <p className="lead">
          <Translate contentKey="home.subtitle">This web service of the ORGA-SOFT Cooperation provides central endpoints
            for INTEGRA. These can be used for the development of a wide range of applications, including web-based
            applications.</Translate>
        </p>
        {account?.login && (
          <div>
            <Alert color="success">
              <Translate contentKey="home.logged.message" interpolate={{ username: account.login }}>
                You are logged in as user {account.login}.
              </Translate>
            </Alert>
          </div>
        )}
        <p>
          <Translate contentKey="home.login-qr">Login to the backend with the QR-Code</Translate>
        </p>
        <QRCodeCanvas
          size={300}
          value={buildQRCode()}
          imageSettings={{src: 'content/images/os-logo-short.png', height: 100, width: 100, excavate: true}}
          level="H"
        />,
      </Col>
    </Row>
  );
};

export default Home;
